<template>
  <div>
    <!-- query nav -->
    <el-form ref="form" :inline="true" :model="queryForm" label-width="80px">
      <el-form-item prop="information">
        <el-input
          v-model="queryForm.information"
          placeholder="请输入操作文件名、操作人进行搜索"
          style="width:280px"
        ></el-input>
      </el-form-item>
      <el-form-item label="操作类型" prop="opType">
        <el-select v-model="queryForm.opType" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属机构" prop="orgId">
        <el-select v-model="queryForm.orgId" filterable placeholder="请选择/输入以搜索">
          <el-option
            v-for="item in orgList"
            :key="item.orgId"
            :label="item.orgName"
            :value="item.orgId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- table -->
    <el-table v-loading="tableLoading" :data="logList">
      <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
      <el-table-column align="center" prop="opFileNames" label="操作文件名" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" width="100" prop="opType" label="操作类型">
        <template slot-scope="{ row }">
          <div>{{ filterType(row.opType) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="100" prop="userName" label="操作人"></el-table-column>
      <el-table-column align="center" width="300" prop="orgName" label="所属机构" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" width="200" prop="createTime" label="操作时间"></el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
  </div>
</template>

<script>
import { getLogList } from '@/api/general-user/internal-data.js';
import { getOrgList } from '@/api/common/index.js';
export default {
  data() {
    return {
      typeList: [
        { value: 1, label: '上传' },
        { value: 2, label: '下载' },
        { value: 3, label: '删除' }
      ],
      tableLoading: false,
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        information: '',
        opType: '',
        orgId: ''
      },
      orgList: [],
      logList: []
    };
  },
  created() {
    this.getLogListData();
    this.getOrgListData();
  },
  methods: {
    getLogListData() {
      this.tableLoading = true;
      getLogList(this.queryForm)
        .then(res => {
          this.total = res.data.totalRows;
          this.logList = res.data.rows;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getOrgListData() {
      getOrgList().then(res => {
        this.orgList = res.data;
      });
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getLogListData();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getLogListData();
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getLogListData();
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.getLogListData();
    },
    filterType(opType) {
      return this.typeList.find(item => item.value === opType).label;
    }
  }
};
</script>
